// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import {
  Button,
  Input,
  SelectAutocomplete,
  SelectSimple,
} from "@components/Input";
// Assets
import { closeSvg } from "@assets";
// Vendor
import { returnElementFromArray } from "vendor/application";
import { Skeleton } from "@mui/material";

class UseCustomFields extends Component {
  componentDidMount = () => {
    const {
      i,
      intent,
      use_custom_fields,
      integrations,
      getCustomFields,
      updateIntent,
    } = this.props;
    const integration_zendesk_full = integrations?.filter(
      (int) => int.channel === "zendesk_full"
    )[0];
    var use_custom = integration_zendesk_full?.multiple.filter(
      (int) => int.use_custom_fields
    );
    if (use_custom?.length === 1) {
      var new_desk_id = use_custom[0].id;
      intent.parameters[i].zendesk_ticket_field.desk_id = new_desk_id;
      updateIntent(intent);
      if (!use_custom_fields || !use_custom_fields[new_desk_id]) {
        getCustomFields("zendesk", new_desk_id, intent);
      }
    }

    const integration_sunco = integrations?.filter(
      (int) => int.channel === "sunco"
    )[0];
    const customActive =
      integration_sunco?.info?.zendesk?.use_custom_fields?.active;
    if (customActive) {
      var sunco_channel_id = integration_sunco.channel_id;
      intent.parameters[i].zendesk_ticket_field.desk_id = sunco_channel_id;
      updateIntent(intent);
      if (!use_custom_fields || !use_custom_fields[sunco_channel_id]) {
        getCustomFields("sunco", sunco_channel_id, intent);
      }
    }
  };

  handleParameters = (e, type, i) => {
    const { intent, updateIntent } = this.props;
    intent.parameters[i].zendesk_ticket_field = {
      id: e.id,
      type: e.type,
      title: e.title,
      equivalences: [],
      desk_id: intent.parameters[i]?.zendesk_ticket_field?.desk_id,
      custom_field_options: e.custom_field_options ?? [],
      custom_field_options_actual: e.custom_field_options
        ? JSON.parse(JSON.stringify(e.custom_field_options))
        : [],
    };
    updateIntent(intent);
  };

  deleteTicketField = (i) => {
    const { intent, updateIntent, getCustomFields } = this.props;
    const newParameters = [...intent.parameters];
    const desk_id = newParameters[i]?.zendesk_ticket_field?.desk_id;
    newParameters[i].zendesk_ticket_field = null;
    updateIntent({ ...intent, parameters: newParameters });
    if (desk_id) {
      getCustomFields("zendesk", desk_id, intent);
    }
  };

  addParameterEquivalence = (i) => {
    const { intent, updateIntent } = this.props;
    intent.parameters[i].zendesk_ticket_field.equivalences.push({
      text: "",
      value: "",
      title: "",
    });
    updateIntent(intent);
  };

  updateParameterEquivalence = (e, parameter, equivalence) => {
    const { intent, updateIntent } = this.props;
    intent.parameters[parameter].zendesk_ticket_field.equivalences[
      equivalence
    ].text = e.target.value;
    updateIntent(intent);
  };

  selectParameterEquivalence = (e, type, i) => {
    const { intent, updateIntent } = this.props;
    var equivalences =
        intent.parameters[i[0]].zendesk_ticket_field.equivalences,
      all_custom_field =
        intent.parameters[i[0]].zendesk_ticket_field.custom_field_options,
      actual_custom_field =
        intent.parameters[i[0]].zendesk_ticket_field
          .custom_field_options_actual;

    if (equivalences[i[1]].value) {
      var element = returnElementFromArray(
        equivalences[i[1]].value,
        all_custom_field,
        "value"
      );
      actual_custom_field.push(element);
    }

    equivalences[i[1]].value = e.value;
    equivalences[i[1]].title = e.name;

    actual_custom_field.map((custom, ii) => {
      if (custom.value === e.value) {
        actual_custom_field.splice(ii, 1);
      }
    });

    intent.parameters[i[0]].zendesk_ticket_field.custom_field_options_actual =
      actual_custom_field;

    updateIntent(intent);
  };

  deleteParameterEquivalence = (parameter, equivalence) => {
    const { intent, updateIntent } = this.props;
    var equivalences =
        intent.parameters[parameter].zendesk_ticket_field.equivalences,
      all_custom_field =
        intent.parameters[parameter].zendesk_ticket_field.custom_field_options,
      actual_custom_field =
        intent.parameters[parameter].zendesk_ticket_field
          .custom_field_options_actual;

    if (equivalences[equivalence].value) {
      var element = returnElementFromArray(
        equivalences[equivalence].value,
        all_custom_field,
        "value"
      );
      actual_custom_field.push(element);
    }

    equivalences.splice(equivalence, 1);
    updateIntent(intent);
  };

  handleSelector = () => {
    const { integrations } = this.props;
    if (integrations.length > 0) {
      const integration = integrations?.filter(
        (int) => int.channel === "zendesk_full"
      )[0];
      var use_custom = integration?.multiple.filter(
        (int) => int.use_custom_fields
      );
      if (use_custom?.length === 1) {
        return false;
      }
      if (use_custom?.length > 1) {
        return true;
      }
    }
    return false;
  };

  updateDeskId = (e) => {
    const { el, i, intent, use_custom_fields, getCustomFields, updateIntent } =
      this.props;
    if (e !== el.zendesk_ticket_field.desk_id) {
      intent.parameters[i].zendesk_ticket_field = { desk_id: e };
      updateIntent(intent);

      if (!use_custom_fields || !use_custom_fields[e]) {
        getCustomFields("zendesk", e, intent, true);
      }
    }
  };

  getDeskId() {
    const { integrations } = this.props;
    const channels = ["sunco", "zendesk_full"];
    for (const channel of channels) {
      const integration = integrations?.find((int) => int.channel === channel);
      if (integration) {
        return integration.id;
      }
    }
    return null;
  }

  render() {
    const { el, i, use_custom_fields, integrations, t } = this.props;

    const integration = integrations.filter(
      (int) => int.channel === "zendesk_full"
    )[0];

    const desk_id = el && el?.zendesk_ticket_field?.desk_id;
    const zendesk_options = use_custom_fields?.[desk_id];
    const has_zendesk = zendesk_options?.length > 0;
    const zendesk_options_title_map = zendesk_options?.map((option) => {
      return {
        ...option,
        title: `${option.title} ${
          option.regexp_for_validation
            ? `(${option.regexp_for_validation})`
            : ""
        }`,
      };
    });
    var desk_name = "-";
    if (el.zendesk_ticket_field && el.zendesk_ticket_field.desk_id) {
      desk_name = integration?.multiple?.filter(
        (int) => int.id === el.zendesk_ticket_field.desk_id
      )[0]?.name;
    }
    if (use_custom_fields) {
      const deskId = el?.zendesk_ticket_field?.desk_id;

      if (deskId && use_custom_fields[deskId]) {
        const lengthCheck = use_custom_fields[deskId]?.length > 0;
      }
    }
    const integration_sunco = integrations?.filter(
      (int) => int.channel === "sunco"
    )[0];
    const customActive =
      integration_sunco?.info?.zendesk?.use_custom_fields?.active;

    if (
      customActive &&
      (!zendesk_options_title_map || zendesk_options_title_map.length === 0)
    ) {
      return (
        <div className={"info__integration_content_skeleton"}>
          <Skeleton variant="rectangular" width="100%" height={10} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </div>
      );
    }
    return (
      <React.Fragment>
        {this.handleSelector() && (
          <div className={"accordion__element_use_custom_fields"}>
            <SelectSimple
              valueSelected={desk_name}
              items={integration.multiple}
              label={t("common:intents.integrations.desk_id")}
              onSelect={(e) => this.updateDeskId(e)}
              placeholder={t("common:intents.integrations.desk_id")}
              value={"id"}
              className={"select_secondary"}
              t={t}
            />
          </div>
        )}
        {has_zendesk && (
          <div className={"accordion__element_use_custom_fields"}>
            <div className={"actions"}>
              <SelectAutocomplete
                value={
                  el.zendesk_ticket_field ? el.zendesk_ticket_field : false
                }
                searchOption={"title"}
                items={zendesk_options_title_map || []}
                className={"select_secondary"}
                onSelect={this.handleParameters}
                placeholder={t(
                  "intents.config.accordion.params.zendesk_custom_field.custom_field"
                )}
                iteration={i}
                t={t}
              />
              <Button
                className={"btn_icon"}
                type={"white"}
                onClick={() => {
                  this.deleteTicketField(i);
                }}
                text={closeSvg()}
              />
            </div>
            {el.zendesk_ticket_field?.custom_field_options?.length > 0 &&
              el.zendesk_ticket_field?.type === "tagger" && (
                <React.Fragment>
                  <Button
                    disabled={
                      el.zendesk_ticket_field.equivalences.length ===
                      el.zendesk_ticket_field.custom_field_options.length
                    }
                    type={"dashed"}
                    onClick={() => {
                      this.addParameterEquivalence(i);
                    }}
                    text={t(
                      "intents.config.accordion.params.zendesk_custom_field.add_button"
                    )}
                  />
                  {el.zendesk_ticket_field.equivalences.map(
                    (equivalence, ii) => {
                      return (
                        <div
                          className={"accordion__element_parameter_equivalence"}
                          key={ii}
                        >
                          <Input
                            id={`equivalences-${ii}`}
                            onChange={(e) => {
                              this.updateParameterEquivalence(e, i, ii);
                            }}
                            value={equivalence.text}
                            placeholder={t(
                              "intents.config.accordion.params.zendesk_custom_field.text"
                            )}
                          />
                          <SelectSimple
                            valueSelected={
                              equivalence.title ? equivalence.title : false
                            }
                            items={
                              el.zendesk_ticket_field
                                .custom_field_options_actual
                            }
                            className={"select_secondary"}
                            onSelect={this.selectParameterEquivalence}
                            placeholder={t(
                              "intents.config.accordion.params.zendesk_custom_field.value"
                            )}
                            iteration={[i, ii]}
                            t={t}
                          />
                          <div
                            onClick={() =>
                              this.deleteParameterEquivalence(i, ii)
                            }
                            className={"delete_equivalence"}
                          >
                            {closeSvg()}
                          </div>
                        </div>
                      );
                    }
                  )}
                </React.Fragment>
              )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};

const accordion_custom_fields = connect(mapStateToProps)(UseCustomFields);

export default withTranslation("common")(accordion_custom_fields);
