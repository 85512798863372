// React
import { connect } from "react-redux";
import React, { Component } from "react";
// Routing
import { withRouter, Switch, Route } from "react-router-dom";
// Infrastructure
import { CustomLogin } from "vendor/infrastructure/custom_requests/login";
// Vendor
import { showNotify, updateCookieStatus } from "vendor/application/disptach";
import { setCookieByName } from "vendor/application";
// Material
import { withTranslation } from "react-i18next";
// Styles
import "views/Root/application/Routes/Landing/styles/index.scss";
// Components
import Login from "views/Root/application/Routes/Landing/application/Login";
import Forgot from "views/Root/application/Routes/Landing/application/Forgot";
import Recover from "views/Root/application/Routes/Landing/application/Recover";
import Verify from "views/Root/application/Routes/Landing/application/Verify";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.base_url = process.env.REACT_APP_CONTACT;
    this.state = {
      cookie: this.props.data.cookie,
      isPageLogin: true
    };
  }

  handleSwitch = (type) => {
    const { history } = this.props;
    var value = "";

    switch (type) {
      case "login":
        history.push(`/landing/${type}?lang=${this.props.i18n.language}`);
        break;
      case "signin":
        window.location.href = `${this.base_url}`;
        break;
      case "forgot":
        history.push(`/password/${type}?lang=${this.props.i18n.language}`);
        break;
      default:
    }
    this.setState({ isPageLogin: value });
  };

  componentDidMount = () => {
    var isUsername = new URL(window.location.href).searchParams.get("u");
    this.handlePathname();

    if (isUsername) {
      this.setState({ logIn: true });
    }
  };

  handlePathname = () => {
    const { location } = this.props;
    if (location.pathname === "/landing/signin") {
        window.open(this.base_url, "_self");
        return;
    }

    if (location.pathname === "/landing/login") {
      this.setState({ isPageLogin: true });
    } 

    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const lang = urlParams.get("lang");

    if (lang && (lang === "es" || lang === "en" || lang === "pt")) {
      this.props.i18n.changeLanguage(lang);
      setCookieByName(lang, "b__lg");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { user, passwd } = this;
    try {
      // GET TOKEN
      let request = new CustomLogin(
        { username: user, password: passwd },
        "token",
        this.props
      );
      let res = await request.make_request_token();

      // GET USER ID FROM TOKEN ACCESS AND UPDATE STATE APP
      var userId = this.getUserId(res.access);
      this.props.handleAccess(res, userId);
      this.props.history.push("/");
    } catch (err) {
      var data = { message: err, severity: "error" };
      this.props.dispatch(showNotify(data));
    }
  };

  getUserId = (access) => {
    var e = this.b64_to_utf8(access.split(".")[1]);
    return JSON.parse(e).user_id;
  };

  b64_to_utf8 = (str) => {
    return decodeURIComponent(escape(window.atob(str)));
  };

  cookie = (e) => {
    e.preventDefault();
    this.setState({ cookie: !this.state.cookie });
    this.props.dispatch(updateCookieStatus(true));
  };

  isVisible = () => {
    const url = window.location.search,
      urlParams = new URLSearchParams(url),
      ref = urlParams.get("ref");

    return !ref;
  };

  render() {
    const { history, t, i18n } = this.props;
    return (
      <React.Fragment>
        <div
          className={`platform_landing_main ${this.isVisible() && "platform_landing_main__footer_active"}`}
        >
          <div
            className={`platform_landing_main__content login`}
          >
              <div className="platform_landing_main__content_background left login">
                <img
                  src={process.env.PUBLIC_URL + "/centribal_login.png"}
                  alt="Centribot by Centribal"
                />
              </div>
            <div className={"platform_landing_main__content_form"}>
              <Switch>
                <Route path="/landing/login">
                  <Login {...this.props} handleSwitch={this.handleSwitch} />
                </Route>
                <Route path="/landing/verify" exact>
                  <Verify handleSwitch={this.handleSwitch} />
                </Route>
                <Route path="/password/forgot" exact>
                  <Forgot handleSwitch={this.handleSwitch} />
                </Route>
                <Route path="/password/recover">
                  <Recover handleSwitch={this.handleSwitch} />
                </Route>
              </Switch>
            </div>
          </div>
          {this.isVisible() && (
            <div className={"platform_landing_main__footer"}>
              <div className={"platform_landing_main__footer_links"}>
                <a
                  href={`https://centribal.com/${i18n.language === "es" ? "es/politica-de-privacidad" : "us/privacy-policy"}`}
                  target={"__blank"}
                >
                  {t("landing.footer.privacy_policy")}
                </a>
                |
                <a
                  href={`${i18n.language === "es" ? "https://centribal.com/es/aviso-legal" : "https://centribal.com/wp-content/uploads/2021/06/LEGAL-NOTICE-2021-centribal.pdf"}`}
                  target={"__blank"}
                >
                  {t("landing.footer.legal_notice")}
                </a>
                |
                <a
                  href={`https://centribal.com/${i18n.language === "es" ? "es/politica-de-cookies" : "us/cookies-policy"}`}
                  target={"__blank"}
                >
                  {t("landing.footer.cookies_policy")}
                </a>
              </div>
              <div className={"platform_landing_main__footer_copyright"}>
                <p>© Centribal</p>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_landing = connect(mapStateToProps)(Landing);

export default withTranslation("common")(withRouter(connect_landing));
